$primary: #ffc841;
$primary-hover: #cda814;

$light-gray: #f2f1f1;
$gray: #838282;

$white: #ffffff;
$sidebar-background: #212121;
$footer_background: black;

// Typo Graphy
$heading-primary: #4c4d4e;

$black: black;

$dorger: #e0fff9;

$bronze: #603b30;
$sliver: #c5c5c5;
$gold: #c57934;
$platinium: #2d2d2d;
