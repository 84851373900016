@import "../../scss/variable";
.card {
  min-height: 100%;
  display: grid;
  grid-template-columns: 1fr;

  &-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 26rem 1fr;
    &-image {
      height: 100%;
      & > img {
        height: 100%;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        width: 100%;
        object-fit: cover;
      }
    }
    &-data {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr auto;
      background-color: $light-gray;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 1.5rem 2rem;
      margin-top: -8px;
      &-location {
        font-size: 1.2rem;
        padding: 0.5rem 0rem;

        & > p {
          margin-left: 1rem;
        }
      }
      &-name {
        & > h3 {
          padding: 0.2rem 0rem;
          display: grid;
          align-items: center;
          justify-content: start;
          grid-template-columns: auto auto;
          gap: 1rem;
          height: 2.2rem;

          & > span {
            font-size: 1.9rem;
            font-weight: 500;
            height: 2.2rem;

            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          & > img {
            width: 1.5rem;
            line-height: 0;
          }
        }
      }
      & > p {
        font-size: 1.2rem;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        margin: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-views {
        font-size: 1.2rem;
        padding: 0.5rem 0rem;
        & > p {
          margin-left: 1rem;
        }
      }
    }
  }
}
