@import "../../../scss/mixins";
@import "../../../scss/variable";

.primary-heading-bg {
  background-color: $primary;
  border-radius: 2rem;
}

.grid-right-side-service {
  width: 50%;

  @include respond(tab-port) {
    width: 95%;
    margin: auto;
  }
}

.grid-left-side-service {
  width: 50%;

  @include respond(tab-port) {
    padding-top: 2em;
    width: 95%;
    margin: auto;
  }
}

.grid-side-service {
  display: flex;
  border-radius: 3rem;
  @include respond(tab-port) {
    flex-direction: column;
  }
  & > * {
    &:first-child {
      margin-right: 4rem;
      @include respond(tab-port) {
        margin: auto;
      }
    }
  }
}
// Event View Point

.eventdetail-view {
  &-detail {
    font-size: 1.4rem;
    & > * {
      &:first-child {
        margin-right: 1.3rem;
      }
    }
  }
}

.service-area-heading {
  & > * {
    &:first-child {
      font-size: 3.5rem;
      margin-right: 1.3rem;
      color: $heading-primary;
    }
  }
}

// Sub Component {
.item-flex {
  &-city {
    & > span {
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background-color: black;
      margin-right: 1.3rem;
      margin-left: 1.5rem;
      // padding: 1rem;
    }
  }
  &-service {
    & > span {
      height: 14px;
      width: 14px;
      background-color: #d9d9d9;
      border-radius: 50%;
      margin-right: 1.3rem;
      margin-left: 1.5rem;
    }
  }
}

// Request Form

.request-form {
  width: 100%;
  background-color: $light-gray;
  border-radius: 2rem;

  &-profile {
    display: flex;
    align-items: center;
    background-color: #dad7d7;
    padding: 3rem;
    border-radius: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-name {
      & > * {
        display: flex;
        align-items: center;
        cursor: pointer;
        & > img {
          margin-right: 2rem;
          height: 53px;
          width: 53px;
          border-radius: 50%;
        }
      }

      &-section {
        & > h4 {
          display: grid;
          align-items: center;
          justify-content: start;
          gap: 1rem;
          grid-template-columns: auto auto;

          & > span {
            font-size: 1.5rem;
            font-weight: 500;
          }

          & > img {
            width: 1.9rem;
            line-height: 0;
          }
        }
      }
    }
    &-icon {
      & > * {
        font-size: 4rem;
      }
    }
  }
  // For form Style
  &-content {
    padding: 3rem;
  }
}

.request-btn {
  & > * {
    background-color: $primary;
    border: none;
    width: 100%;
    padding: 2rem 0rem;
    border-radius: 2rem;
    font-size: 1.3rem;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
      background-color: $primary-hover;
    }
  }
}

.like-container-f2ga {
  padding: 0 5rem;

  & > .eventdetail-view-detail {
    font-size: 2rem;
  }
}
