@import "../../scss/variable";

.selector {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 20rem;
  height: 3rem;
  flex-shrink: 0;
  padding: 1rem;
  &-container {
    position: relative;
    width: 100%;
    &-top {
      padding: 0.3rem 1.5rem;
      display: flex;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
      & > h6 {
        font-size: 1.4rem;
        font-weight: 600;
      }
      &-icon {
        & > * {
          font-size: 2rem;
          margin-top: 5px;
        }
      }
    }
    // Seletor
    &-selector {
      margin-top: 1rem;
      position: absolute;
      width: 100%;
      z-index: 100000000000;
      background-color: $light-gray;
      border: 1px solid $gray;
      border-radius: 5px;
      height: 250px;
      overflow-y: hidden;
      overflow-y: scroll;
      &-content {
        padding: 1rem 1.7rem;
      }
      &-item {
        margin: 0.5rem 0rem;
        padding: 0.5rem 1rem;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        font-size: 1.2rem;
        &:hover {
          background-color: white;
        }
      }
    }
  }
}

.selector-rounded {
  border-radius: 4rem;
}

.selector-h {
  height: 5rem !important;
}
