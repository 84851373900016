@import "../../scss/variable";
@import "../../scss/mixins";
.forminput-container {
  & > input {
    padding: 1.6rem 1rem;
  }
  & > div > input {
    padding: 1.6rem 1rem;
  }
  & > select {
    padding: 1.6rem 1rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg fill='rgba(128, 128, 128)' height='28' viewBox='0 0 24 24' width='28' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 10px;
  }
}

.form {
  &-label {
    font-size: 1.5rem;
    display: block;
    margin: 2rem 0.3rem;
    text-transform: capitalize;
    @include respond(tab-port) {
      font-size: 2rem;
    }
  }
  &-input {
    width: 95%;
    color: black;
    border-radius: 1.3rem;
    border: none;
    background-color: $light-gray;
    &:focus {
      outline: none;
    }
    @include respond(tab-port) {
      font-size: 2rem;
    }
  }
  &-error {
    margin-left: 15px;
    color: red;
    font-size: 10px;
    font-weight: 400;
  }
}

.is-invalid {
  border: 1px solid rgba(255, 0, 0, 0.65);
}
.form-text {
  border: 1.5px solid rgba(128, 128, 128, 0);
  // border: none;
  border: transparent;
  border-radius: 1rem;
  padding: 1rem 1rem;
  background-color: $light-gray;
  width: 95%;
  &::-webkit-input-placeholder {
    /* Edge */
    color: rgba(128, 128, 128, 0.574);
    font-size: 1.5rem;
  }
  &:focus {
    outline: none;
  }
}

.bg-white {
  background-color: white;
}
