@import "./scss/mixins";
@import "./scss/variable";

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

body {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

  @include respond(tab-port) {
    // width < 900?
    font-size: 52%; //1 rem = 8px, 8/16 = 50%
  }
  @include respond(tab-land) {
    // width < 1200?
    font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 60%; //1rem = 12, 12/16
  }
}

a {
  text-decoration: none;
}

.uppercase {
  text-transform: uppercase;
}

.lowercase {
  text-transform: lowercase;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.text-left {
  text-align: left;
}

.error {
  font-size: 1.4rem;
  color: rgb(219, 42, 42);
  font-weight: 500;
}

textarea {
  resize: vertical;
  width: 100%;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 100rem;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
