@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.servicedetail {
  &-title{
    background: $primary;
    padding: .5rem 3rem;
    border-radius: 20px;
    margin-right: 2rem;
  }
  &-container {
    display: flex;
    flex-direction: row;
    padding: 1rem 4rem;
    gap:24px;
    //on small screens flex-direction should be columbs
    @include respond(tab-port) {
      flex-direction: column;
    }
    &>:nth-child(2){
      width: 40%;
      @include respond(tab-port) {
      width: 100%;
      }
    }
    &>:first-child{
      @include respond(tab-port) {
      width: 100%;
      }
      width: 60%;
      // border: 1px solid $gray;
      &>span{
        display: block;
        background-color: $primary;
        // border: 1px solid red;
        margin: 1rem 0;
        width: 100%;
        height: 2px;
      }
      &>div{
        padding: 1rem;
        & >h3{
          font-size: large;
          font-weight: 600;
        }
        &>p{
          font:normal;
          text-indent: 1rem;
          padding: .5rem 0;
        }
        &>ul{
          list-style-position: inside;
          padding-left: 1rem;
          font-weight:  400;
          &>li{
            font-size: small;
            margin: 1rem 0;
          }
        }
      }
    }
    &-area {
      & > h6 {
        color: $heading-primary;
        font-size: 1.1rem;
        font-weight: 700;
      }
      & > p {
        color: $gray;
        font-size: 1.4rem;
        margin: 0.8rem 0rem;
        display: flex;
        align-items: center;
      }
      &-icon {
        font-size: 1.5rem;
        color: black;
        margin-right: 0.7rem;
      }
    }

    &-image {
      width: 100%;
      // border: 1px solid $primary;
      border-radius: 15px;
      @include respond(tab-port) {
        margin: auto;
        max-width: 100%;
      }
      &-section {
        margin: 4rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond(tab-port) {
          flex-direction: column;
        }
        & > img {
          width: 100%;
          height: 500px;
          border-radius: 15px;
          object-fit: cover;
          @include respond(tab-port) {
            margin-top: 2rem;
            width: 100%;
          }
        }
      }
    }
  }
}

.about-service{
  & >h3{
    font-size: larger;
    font-weight: 500;
  }
  &>p{
    font:normal
  }
  &>ul{
    list-style-position: inside;
  }
}
.vender-message-tab{
  // border: 1px solid $gray;
  background-color: $light-gray;
  width: 100%;
  border-radius: 16px;
  overflow: hidden;
  // padding: 2rem;
  &-heading{

    padding: 2rem;
    border-radius: 12px;
    background-color: #DAD7D7;
    display: flex;
    align-items: center;
    justify-content: space-between;
    & img{
      border: 1px solid $primary;
      border-radius: 100%;
      width: 50px;
      height: 50px;
      background:#ffffff;
    }
    & span{
      font-size: 3rem;
      color: black;
      font-weight: 600;
    }
    &-text{
      padding: 0 1rem ;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      &>div{
      font-size: 2rem;
      }
      &>p{
        font-size: 1.5rem;
        // color:white
      }
    }
  }
  form{
    // border: 1px solid red;
    padding: 2rem 4rem;
    display: flex;
    flex-direction: column;
    &>input,textarea,select{
      background-color: white;
      border: none;
      width: 100%;
      outline: 2px solid $primary;
      border-radius: 12px;
      // padding: 1rem 1.5rem;
      padding: 1rem 0;
      font-size: 2rem;
    }
    & >label{
      padding: 1rem 0;
      font-size: 2rem;
      color: black;
      font-weight: 400;
    }
    &>button{
      padding: 2rem;
    }
  }
}


.not-ready{
  font-size:2rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}