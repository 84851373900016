@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.customer-container-2g2 {
  @include respond(tab-port) {
    margin: 0;
    width: 100%;
    padding: 0 2rem;
    box-sizing: border-box;
  }
}

.table-container {
  @include respond(tab-port) {
    width: 100%;
    margin: 0;
    overflow: auto;
  }

  /* Handle on hover */
}
.myservices-table {
  @include respond(tab-port) {
    min-width: 900px;
    margin: 0;
  }

  & > table {
    width: 100%;
    box-sizing: content-box;

    & > thead {
      & > tr {
        & > th {
          color: $gray;
          text-align: left;
          padding: 0.6rem 1rem;
          font-size: 1.4rem;
        }
        & > * {
          &:first-child {
            width: 20px;
          }
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          font-size: 1.2rem;
          padding: 0.6rem 1rem;
          font-weight: 500;
        }
        & > * {
          &:first-child {
            width: 20px;
            text-align: center;
            font-weight: bold;
          }
          &:last-child {
            width: 115px;

            & > * {
              font-size: 1.7rem;
              cursor: pointer;
              transition: 0.3s ease-in-out;
              &:first-child {
                &:hover {
                  color: rgb(37, 119, 234);
                }
              }
              &:last-child {
                &:hover {
                  color: rgb(237, 53, 53);
                }
              }
              &:hover {
                color: green;
              }
              &:not(:last-child) {
                margin-right: 15px;
              }

              &:is(div) {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}

.my-services-tab {
  border: 1px solid $gray;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: space-between;
  padding: 2rem;
  margin: 2rem;

  & > :first-child {
    & > :first-child {
      & > :first-child {
        font-weight: 600;
        font-size: 1.2rem;
        color: black;
      }
      & > :nth-child(2) {
        font-size: 1rem;
        color: $gray;
      }
    }
    & > span {
      display: flex;
      gap: 1rem;
      & > * {
        background-color: transparent;
        color: $gray;
        font-weight: 500;
        font-size: 1.5rem;
      }
    }
  }

  // &>:nth-child(2){
  //   &>:first-child {
  //     font-weight: 600;
  //     font-size: 2rem;
  //   }
  // }

  & > hr {
    color: $gray;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > span {
      display: flex;
      gap: 1rem;
      & > button {
        background-color: transparent;
        outline: none;
        border: none;
        color: $gray;
      }
    }

    & > :first-child {
      font-weight: 500;
      color: black;
    }
    & > div {
      font-weight: bolder;
      font: 3rem;
    }
  }
  & > b {
    // font-size: 3rem;
    // font-weight: bold;
    // color: black;
  }
  & > p {
    color: $gray;
  }

  & > span {
    display: flex;
    gap: 1rem;
    & > button {
      background-color: transparent;
      outline: none;
      border: none;
    }
  }
}
.count {
  color: $gray;
  padding-left: 3rem;
  font-size: 1.5rem;
  font-weight: 600;
}

//
