.question {
  display: flex;

  & > * {
    &:not(:last-child) {
      margin-right: 1.5rem;
    }
  }
}

.faq {
  &-hero {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    & > img {
      width: 100%;
      max-height: 35rem;
      object-fit: cover;
      object-position: center;
    }
  }

  &-main {
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
  }
}
