@import "../../scss/variable";
@import "../../scss/mixins";

.reviewsection {
  background-image: url(../../assests/background.webp);
  height: 50vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include respond(tab-port) {
    height: 50vh;
  }
  @include respond(big-desktop) {
    height: 50vh;
  }
}

.reviewcard {
  background-color: white;
  border: 3px solid $primary;
  border-radius: 2rem;
  width: 80%;
  margin: auto;
  @include respond(tab-port) {
    width: 95%;
    padding-top: 1rem;
  }

  &-container {
    padding: 4rem;
    @include respond(tab-port) {
      padding: 1.5rem;
    }
    & > p {
      font-size: 2rem;
      font-weight: 300;
      @include respond(tab-port) {
        font-size: 1.2rem;
      }
    }
    &-name {
      & > h5 {
        font-size: 1.2rem;
      }
      & > p {
        font-size: 1.2rem;
        margin: 5px 0px;
      }
    }
  }
}
