@import "../../scss/variable";
@import "../../scss/mixins";

.productslider {
  margin: 5rem 0rem;
  @include respond(tab-port) {
    width: 90%;
    margin: auto;
    padding: 5rem 0rem;
  }
}

.slick-prev:before,
.slick-next:before {
  color: $primary !important;
}
