@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.payment {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    align-items: center;
    @include respond(tab-port) {
      flex-direction: column;
    }
    &-card {
      width: 70%;

      @include respond(tab-port) {
        width: 100%;
      }
    }
    &-button {
      @include respond(tab-port) {
        width: 100%;
        text-align: center;
        margin: 5rem 0rem;
      }
    }
  }
}

.payment-card {
  background: #f2f1f1;
  border: 1px solid $primary;
  padding: 4rem 3.5rem;
  border-radius: 3rem;
  &-heading {
    & > h3 {
      font-size: 1.8rem;
      font-weight: 600;
    }
    & > * {
      &:last-child {
        font-size: 2rem;
        cursor: pointer;
      }
    }
  }
  &-number {
    margin: 4rem 0rem;
    & > img {
      height: 4.6rem;
      width: 4.6rem;
      border-radius: 50%;
      margin-right: 2rem;
    }
    & > p {
      font-size: 2rem;
    }
  }
  &-cvc {
    & > h3 {
      font-size: 1.8rem;
      font-weight: 600;
    }
    & > p {
      margin-top: 1rem;
      font-size: 2rem;
    }
  }
}
