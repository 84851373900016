@import "../../../../scss/variable";
@import "../../../../scss/mixins";
.findmore {
  margin: 9rem 0rem;
  &-table {
    & > table {
      width: 100%;
      border-radius: 4rem;
      background-color: #f2f1f1;
      @include respond(tab-port) {
        width: 1000px;
        overflow: hidden;
      }
      & > thead {
        & > tr {
          border: none;
          & > th {
            font-size: 1.4rem;
            font-weight: 600;
            padding: 1.6rem 0rem;
            border: none;
          }
          & > * {
            &:not(:last-child) {
              border-right: 1px solid rgba(128, 128, 128, 0.372);
            }
          }
        }
      }
      & > tbody {
        & > tr {
          border: none;
          & > td {
            border: none;
            font-size: 1.3rem;
            font-weight: 400;
            text-align: center;
            padding: 2rem 1.5rem;
            border-top: 1px solid rgba(128, 128, 128, 0.372);
          }
          & > * {
            &:not(:last-child) {
              border-right: 1px solid rgba(128, 128, 128, 0.372);
            }
          }
        }
      }
    }
  }
}

.memberships-bottom-buttons {
  padding: 3rem 0rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
