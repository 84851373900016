.changepassword {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  &-container {
    text-align: center;
    width: 280px;
    & > img {
      width: 50%;
    }
  }
}
