@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.uploadimages-btn {
  & > div {
    background-color: $primary;
    width: 20rem;
    padding: 1rem 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.4rem;
    border-radius: 1rem;
    cursor: pointer;
  }
}

// Images Preview Section
.create-service-images_preview {
  width: 100%;
  border: 1px solid $primary;
  border-radius: 15px;
  @include respond(tab-port) {
    margin: auto;
    max-width: 100%;
  }

  &-images {
    margin: 4rem;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    gap: 1rem;

    &-item {
      width: 100%;
      & > img {
        width: 100%;
        height: 300px;
        border-radius: 15px;
        object-fit: cover;
      }
      @include respond(tab-port) {
        margin-top: 2rem;
        width: 100%;
      }
    }
  }
}

// Service Area Form

.add_service_area-form {
  display: flex;
  align-items: center;
  margin-top: 2rem;
  & > * {
    &:first-child {
      width: 80%;
      margin-right: 2rem;
      padding: 1.4rem 1rem;
      border-radius: 0.5rem;
      border: 1px solid black;

      &:focus {
        outline: none;
      }
    }
  }
  & > button {
    width: 20%;
    // margin-top: 5rem;
    padding: 1.5rem 0rem;
    background-color: $primary;
    border: none;
    border-radius: 8px;
    text-transform: uppercase;
    margin-right: 2rem;
    cursor: pointer;
  }
}

.add_service_area-form-container {
  margin-top: 3.5rem;
  & > label {
    margin-top: 2rem;
    font-size: 1.2rem;
  }
}

// selector Item Style

.selector-form-item-container {
  // margin-right: 2rem;

  & > label {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    // padding-bottom: 2rem;
  }
}

.selector-form-item-row {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 0.4rem;

  & > button {
    font-size: 1.4rem;
    background-color: #ffc841;
    border: none;
    outline: none;
    padding: 0.8rem 1.6rem;
    border-radius: 1000rem;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
}

.image-selector-custom {
  background-color: #f2f1f1;
  border: 0.1rem solid #ffc841;
  border-radius: 1.2rem;
  min-height: 30rem;
  padding: 0.8rem 1.6rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.8rem;

  & > .images-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
    gap: 0.8rem;

    & > div {
      position: relative;
      & > img {
        width: 100%;
        height: 25rem;
        object-fit: cover;
        border-radius: 0.8rem;
        box-sizing: border-box;
        cursor: pointer;
        transition: opacity 300ms ease-in-out, border-width 100ms ease-in-out;

        &.selected {
          border: 4px solid #ffc841;
        }

        &:hover {
          opacity: 0.7;
        }
      }

      & > button {
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: grid;
        background-color: transparent;
        outline: none;
        border: none;

        color: rgb(235, 36, 36);
        font-size: 2.8rem;
        z-index: 2;
        cursor: pointer;
        transition: opacity 300ms ease-in-out;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }

  & > .imageSelector {
    justify-self: center;
    align-self: center;

    background-color: #ffc841;
    border-radius: 0.8rem;
    padding: 0.8rem 1.6rem;
    width: fit-content;
    font-weight: 500;
    color: #363636;
    cursor: pointer;
    user-select: none;

    &:hover {
      opacity: 0.5;
    }

    & > input {
      display: none;
    }
  }
}

.server-area-af2lj2g {
  &,
  & > div {
    & > .input-afl2jf2j {
      padding: 1.2rem 1.6rem;
      border-radius: 0.8rem;
      background-color: transparent;
      outline: none;
      border: 0.1rem solid rgba($color: #000000, $alpha: 0.3);
      font-size: 1.4rem;
      width: 100%;
      box-sizing: border-box;
      font-family: "Montserrat", sans-serif;
    }
  }

  & > .group-2f2t {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: 0.4rem;

    & > p {
      display: grid;
      grid-template-columns: auto auto;
      gap: 0.4rem;
      padding: 0.4rem 0.8rem;
      border-radius: 1000rem;
      background-color: #0096fa;
      color: white;
      font-size: 1.2rem;

      & > button {
        font-size: 1.4rem;
        background-color: transparent;
        border: none;
        display: grid;
        place-items: center;
        color: white;
        cursor: pointer;

        &:hover {
          opacity: 0.5;
        }
      }
    }
  }
}

.server-area-232g2 {
  display: grid;
  gap: 0.5rem;

  & > label {
    display: grid;
    width: fit-content;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;

    & > span {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }
}

.multi-select-input-32f24 {
  & > div:first-child {
    padding: 1.2rem 1.6rem;
    border-radius: 0.8rem;
    background-color: transparent;
    outline: none;
    border: 0.1rem solid rgba($color: #000000, $alpha: 0.3);
    width: 100%;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    min-height: 4.4rem !important;

    & > input {
      font-family: "Montserrat", sans-serif;
      margin: 0 !important;
      min-width: 18rem;

      &::placeholder {
        font-family: "Montserrat", sans-serif;
      }
    }
  }

  &.only-one > div:first-child {
    & > .chip.singleChip {
      margin: 0 !important;
      padding: 0 !important;
      font-size: 1.4rem;
      font-family: "Montserrat", sans-serif;

      & > img {
        display: none;
      }
    }
  }

  &.multiple > div:first-child {
    display: flex;
    row-gap: 1rem;
    column-gap: 0.5rem;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;

    & > .chip {
      margin: 0 !important;
      padding: 0.2rem 0.8rem !important;
      font-size: 1.4rem;
      font-family: "Montserrat", sans-serif;
    }
  }
}
