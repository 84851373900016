// UpGrade Card
@import "../../../../scss/mixins";
@import "../../../../scss/variable";

.upgrade {
  & > h3 {
    font-size: 1.6rem;
    font-weight: 500;
    margin-left: 2rem;
  }

  &-container {
    border: 1px solid gray;
    margin-top: 3rem;
    padding: 3rem;
    border-radius: 3rem;
  }
}

.card-margins {
  margin: 10.5rem 0rem;
}

.select-promocode-select {
  margin: 2rem 0rem;
}

.select-promocode-select {
  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 3rem;

    & > h5 {
      font-size: 1.6rem;
      font-weight: 400;
    }

    & > p {
      font-size: 1.3rem;
    }
  }
}

.unqiue-id-aslkdjf2325k2j {
  max-width: 134.3rem;
  margin: 0 auto;
  padding: 4.4rem 5rem 11.9rem;
  display: grid;
  gap: 9.4rem;

  & > * {
    box-sizing: border-box;
  }

  @include respond(phone) {
    padding: 9.4rem 2rem 11.9rem;
  }

  & > .top {
    display: grid;
    row-gap: 4.2rem;
    column-gap: 8.7rem;

    grid-template-columns: 1fr 1fr;

    @include respond(tab-land) {
      grid-template-columns: 1fr;
    }

    & > div {
      display: grid;
      gap: 4.2rem;
      grid-template-rows: auto 1fr;

      & > h2 {
        color: #808080;
        font-family: Montserrat;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      & > .box {
        border-radius: 2.9rem;
        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);
      }

      &.box-2 > .box {
        display: grid;
        gap: 4.2rem;
        align-content: start;

        @include respond(phone) {
          padding: 3.2rem;
          // width: 70vw;
        }

        & > div {
          display: grid;
          gap: 1.2rem;

          & > h6 {
            color: #a0a0a0;
            font-family: Montserrat;
            font-size: 1.7825rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & > p {
            color: #575757;
            font-family: Montserrat;
            font-size: 1.7825rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      &.box-2 > .box {
        padding: 3.7rem 9.9rem 2.9rem 3.6rem;
        display: grid;
        gap: 1.9rem;
        align-content: start;

        @include respond(phone) {
          padding: 3.7rem 3.2rem 2.9rem;
        }
      }

      &.box-3 > .box {
        padding: 3.7rem 9.9rem 2.9rem 3.6rem;
        display: grid;
        gap: 4.2rem;
        align-content: start;

        @include respond(phone) {
          padding: 3.7rem 3.2rem 2.9rem;
        }

        & > div {
          display: flex;
          gap: 4.2rem;

          & > h6 {
            color: #a0a0a0;
            font-family: Montserrat;
            font-size: 1.7825rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & > p {
            color: #575757;
            font-family: Montserrat;
            font-size: 1.7825rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
        }
      }

      &.box-4 > .box {
        display: flex;
        align-content: start;
        gap: 6.745rem;
        padding: 4.3rem;
        flex-direction: column;

        @include respond(phone) {
          padding: 4.3rem;
        }

        & > .row {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          & > h6 {
            color: #808080;
            font-family: Montserrat;
            font-size: 1.9825rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & > span {
            margin: 1rem 0rem;
            color: #575757;
            font-family: Montserrat;
            font-size: 1.7825rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          & > p {
            margin: 1rem 0rem;
            color: #575757;
            font-family: Montserrat;
            font-size: 1.7825rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }

        & > .bottom {
          & > p {
            color: #575757;
            font-family: Montserrat;
            font-size: 1.7825rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }

      & > .bottom {
        display: grid;
        gap: 2.7rem;

        & > .bottom {
          display: grid;
          grid-template-columns: auto;
          justify-content: end;
        }
      }
    }
  }

  & > .bottom {
    display: grid;
    grid-template-columns: auto;
    justify-content: end;
    margin-top: -5rem;
  }
}

.InputField-2349823981nidsfwe {
  display: grid;
  gap: 1.7rem;
  align-content: start;
  margin-top: 2rem;

  & > h6 {
    color: #808080;
    font-family: Montserrat;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & > div {
    display: grid;
    gap: 1rem;
    align-content: start;

    &,
    & > div {
      & > input {
        width: 100%;
        outline: none;
        border-radius: 2.9rem;
        border: none;

        box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

        color: #575757;
        font-family: Montserrat;
        font-size: 1.7825rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 2rem 2.1rem;
        box-sizing: border-box;
      }
    }

    & > p {
      color: #d42020;
      font-family: Montserrat;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.InputField-2324232098238 {
  display: grid;
  gap: 2.7rem;
  align-content: start;

  & > h6 {
    color: #808080;
    font-family: Montserrat;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  & > div {
    width: 100%;
    outline: none;
    border-radius: 2.9rem;
    border: none;

    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.25);

    box-sizing: border-box;
    padding: 2rem 2.1rem;
  }
}

.stripe-input-23423824sd {
  color: #575757;
  font-family: Montserrat;
  font-size: 1.7825rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.button-9274982734987324afasdf {
  border-radius: 2.9rem;
  border: 0.3rem solid #ffc841;
  background-color: #ffc841;
  outline: none;
  margin: 1.5rem 0rem;

  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 0.9rem 4.5rem;
  cursor: pointer;

  transition: background-color 300ms ease-in-out;

  &:disabled {
    background-color: #eae7e7 !important;
  }

  &:not(:disabled):hover {
    background-color: #ffc9418e !important;
  }
}

.button-sdkjfkj2939j3f23 {
  border-radius: 2.9rem;
  border: 0.3rem solid #ffc841;
  background-color: #fff;
  outline: none;

  color: #000;
  text-align: center;
  font-family: Montserrat;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 0.9rem 4.5rem;
  cursor: pointer;

  transition: background-color 300ms ease-in-out;

  &:disabled {
    background-color: #eae7e7 !important;
  }

  &:not(:disabled):hover {
    background-color: #ffc94136 !important;
  }
}

.center-loader-nlkj21j3 {
  min-height: 80vh;
  display: grid;
  justify-content: center;
  align-items: center;
}

.confirmation-modal-lksadjf239jf2 {
  display: grid;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  min-height: 100svh;
  z-index: 13000 !important;

  & > .confirmation-modal-239jv2 {
    border-radius: 8.2rem;
    border: 0.1rem solid #ffc841;
    background: #fff;
    max-width: 82.2rem;
    width: 95vw;
    padding: 8.1rem 2rem 6.3rem;
    display: grid;
    gap: 8.3rem;
    justify-items: center;
    z-index: 1000;
    overflow: auto;
    max-height: 80svh;

    @include respond(tab-port) {
      width: 80vw;
    }

    & > .top {
      display: grid;
      justify-items: center;
      gap: 5.7rem;

      & > h6 {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 2.4rem;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      & p {
        color: #000;
        text-align: center;
        font-family: Montserrat;
        font-size: 2rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &.text-left {
          text-align: left;
        }

        &.pb {
          padding-bottom: 2rem;
        }
      }

      & ol {
        list-style-position: inside;
        & li {
          font-family: Montserrat;
          font-size: 2rem;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &:not(:last-child) {
            padding-bottom: 3rem;
          }

          & ul {
            padding-left: 4rem;
          }
        }
      }
    }

    & > .bottom {
      display: grid;
      align-items: center;
      justify-content: center;
      gap: 1.8rem;
      grid-template-columns: auto auto;
    }
  }
}

.bg-bronze {
  background-color: $bronze;
}

.bg-sliver {
  background-color: $sliver;
}

.bg-gold {
  background-color: $gold;
}

.bg-platinium {
  background-color: $platinium;
}
