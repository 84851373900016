@import "../../scss/mixins";

.slider-images {
  & > img {
    width: 100%;
    height: 70vh;
    object-fit: cover;
    border-radius: 3rem;
    @include respond(tab-port) {
      width: 100%;
      margin: auto;
      height: 260px;
    }
    @include respond(big-desktop) {
      height: 600px;
    }
  }
}

.image-slider-container {
  width: 90%;
  margin: auto;
}
