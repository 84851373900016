@import "../../scss/mixins";

.grid {
  display: grid;
}
.grid-2 {
  grid-template-columns: auto auto;
  grid-gap: 25px;
  @include respond(tab-port) {
    grid-template-columns: auto;
  }
}

.grid-3 {
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 25px;
  @include respond(tab-land) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(tab-port) {
    grid-template-columns: auto;
  }
}
.grid-4 {
  grid-template-columns: auto auto auto auto;
  grid-gap: 25px;
  @include respond(tab-land) {
    grid-template-columns: auto auto;
  }
  @include respond(tab-port) {
    grid-template-columns: auto;
  }
}
