@import "../../../../scss/variable";

.edit-profile {
  &-image {
    & > img {
      height: 150px;
      width: 150px;
      border-radius: 50%;
      object-fit: cover;
      border: 3px solid $primary;
    }
    & > * {
      &:last-child {
        margin-left: -40px;
        background-color: $primary;
        height: 25px;
        width: 25px;
        border-radius: 50%;
        padding: 0.5rem;
        cursor: pointer;
        &:hover {
          background-color: $primary-hover;
        }
      }
    }
  }
}
