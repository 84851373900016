@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.customer-request-table {
  & > table {
    width: 100%;
    @include respond(tab-port) {
      width: 1000px;
      overflow: hidden;
    }
    & > thead {
      & > tr {
        & > th {
          color: $gray;
          text-align: left;
          padding: 0.6rem 1rem;
          font-size: 1.4rem;
        }
        & > * {
          &:first-child {
            width: 20px;
          }
          &:last-child {
            text-align: center;
          }
        }
      }
    }

    & > tbody {
      & > tr {
        & > td {
          font-size: 1.2rem;
          padding: 0.6rem 1rem;
          font-weight: 500;
          text-transform: capitalize;
        }
        & > * {
          &:first-child {
            width: 20px;
            text-align: center;
            font-weight: bold;
          }
          &:last-child {
            display: flex;
            // text-align: center;
            align-items: center;
            justify-content: center;

            & > * {
              &:not(:last-child) {
                margin-right: 1rem;
              }
            }
          }
        }
      }
    }
  }
}
.customer-request-tab {
    border:1px solid $gray;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    padding: 2rem;
    margin: 2rem;

    &>:first-child{
      &>:first-child {
        font-weight: 600;
        font-size: 1.4rem;
      }
    }

    &>:nth-child(3){
      &>:first-child {
        font-weight: 600;
        font-size: 1.5rem;
      }
    }

    & >hr{
      color: $gray;
    }

    & > div{
     display: flex;
    justify-content: space-between;
    align-items: center;

    & >span{
      display: flex;
      gap: 1rem;
      &>button{
        background-color: transparent;
        outline: none;
        border: none;
        color: $gray;
      }
    }

      &>:first-child{
        font-weight: 500;
        color:black
      }
      &> div{
        font-weight: bolder;
        font:3rem;
      }
    }
    &>b{
      // font-size: 3rem;
      // font-weight: bold;
      // color: black;
    }
    &>p{
      color: $gray;
    }
    
    & >span{
      display: flex;
      gap: 1rem;
      &>button{
        background-color: transparent;
        outline: none;
        border: none;
      }
    }
}
.arrow-link{
  font:bold;
  font-size: 2rem;
}

.rejection-modal{
  &> form{
    display: flex;
    flex-direction: column;
    gap:1rem;
    font-size: larger;

    &>div{
      align-self: flex-end;
      display: flex;
      gap:1rem;
    }
  }
}