@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.addcard {
  &-container {
    display: flex;
    @include respond(tab-port) {
      flex-direction: column;
      width: 90%;
      margin: auto;
    }
    &-form {
      width: 100%;
      margin-right: 4rem;
      @include respond(tab-port) {
        width: 100%;
        margin-right: 0rem;
      }
    }
    // card Section
    &-card {
      width: 35%;
      background-color: $dorger;
      padding: 4rem 0rem;
      border-radius: 3rem;

      @include respond(tab-port) {
        width: 100%;
        margin-top: 4rem;
      }
      &-content {
        &-image {
          display: flex;
          align-items: center;
          justify-content: center;
          & > img {
            width: 300px;
          }
        }
        &-data {
          width: 70%;
          margin: auto;
          padding: 2rem 0rem;
          & > p {
            font-size: 1.3rem;
            margin: 1.6rem 0rem;
          }
        }
        &-pay {
          & > p {
            font-size: 1.3rem;
            margin: 1.6rem 0rem;
          }
        }
      }
    }
  }
}

.addcardinput-heading {
  & > h3 {
    font-size: 1.6rem;
  }
  & > p {
    font-size: 1.3rem;
    margin: 1rem 0rem;
  }
}

.addcardinput-field-cardnumber {
  //   margin: 2rem 0rem;

  margin-right: 4rem;
  @include respond(tab-port) {
    margin-right: 0rem;
  }
  display: flex;
  align-items: center;
  & > img {
    margin-right: 2rem;
    height: 4rem;
    width: 4rem;
    border-radius: 50%;
  }
  & > div {
    flex: 1;
    border: 1px solid #aaa;
    padding: 1.8rem 2rem;
    border-radius: 0.8rem;

    & * {
      font-size: 1.8rem;
    }
  }
}

.card-input-flex {
  margin: 4rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > h3 {
    width: 40%;
    margin-top: 1.3rem;
    font-size: 1.6rem;
  }
  & > div {
    width: 100%;
    margin-right: 4rem;
    display: flex;
    @include respond(tab-port) {
      margin-right: 2rem;
    }

    & > * {
      width: 50%;
      &:not(:last-child) {
        margin-right: 3rem;
      }
    }
  }
}

.cardform-button {
  margin-right: 4rem;
  margin-top: 4rem;
  @include respond(tab-port) {
    margin-right: 0rem;
  }
  & > button {
    width: 100%;
    padding: 2rem 0rem;
    background-color: $primary;
    font-size: 1.8rem;
    font-weight: 700;
    border-radius: 0.8rem;
    border: none;
    cursor: pointer;
  }
}
