@import "../../../scss/variable";
@import "../../../scss/mixins";

.sidebar {
  background-color: $sidebar-background;
  position: relative;
  @include respond(tab-port) {
    position: absolute;
    height: 100vh;
    z-index: 10000;
  }
  &-container {
    &-name {
      padding: 1.7rem 1.5rem;
      border-bottom: 2px solid $gray;
      color: white;

      &-icon {
        font-size: 2rem;
        margin-right: 1rem;
        cursor: pointer;
        color: white;
      }

      &-user {
        font-weight: 500;
        font-size: 1.9rem;
        color: $gray;
      }
    }

    &-menu {
      padding: 1.6rem;
      &-list {
        &-link {
          margin: 5px 0px;
          padding: 1rem 0rem;
          &-icon {
            color: $primary;
            margin-right: 1rem;
            font-size: 1.9rem;
          }
          &-name {
            color: rgba(255, 255, 255, 0.603);
            font-size: 1.3rem;
            font-weight: 300;
            &::before {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.logout-btn-sidebar {
  margin-top: 5rem;
  background-color: $primary;
  color: black;
  border: none;
  width: 85%;
  padding: 0.8rem 0rem;
  font-weight: bold;
  border-radius: 1rem;
  cursor: pointer;
  position: absolute;
  bottom: 3rem;
}

#active {
  color: $primary;
}
