// UpGrade Card

.upgrade {
  & > h3 {
    font-size: 1.6rem;
    font-weight: 500;
    margin-left: 2rem;
  }
  &-container {
    border: 1px solid gray;
    margin-top: 3rem;
    padding: 3rem;
    border-radius: 3rem;
  }
}

.card-margins {
  margin: 10.5rem 0rem;
}

.select-promocode-select {
  margin: 2rem 0rem;
}

.select-promocode-select {
  &-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 3rem;
    & > h5 {
      font-size: 1.6rem;
      font-weight: 400;
    }
    & > p {
      font-size: 1.3rem;
    }
  }
}
