@import "../../scss/variable";

.infoitem {
  & > h6 {
    color: $heading-primary;
    font-size: 2rem;
    font-weight: 700;
    padding-bottom: 0.6rem;
  }
  & > p {
    color: $gray;
    font-size: 1.4rem;
    margin: 0.3rem 0rem;
    word-wrap: break-word;
  }
}
