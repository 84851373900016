@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.membership-top-section {
  background-color: #f2f1f1;
  padding: 0.2rem 3rem;
  border-radius: 3rem;
}

.membershipcard {
  //add padding on y-axis
  padding: 1rem 0rem;
  width: 100%;
  position: relative;
  overflow: hidden;
  &-container {
    clip-path: polygon(100% 0, 100% 33%, 0 100%, 0 66%);
    background-color: black;
    height: 600px;
    @include respond(tab-port) {
      height: 500px;
    }
    // z-index: -100000;
    &-content {
      position: absolute;
      display: relative;
      left: 10%;
      right: 10%;
      top: 7%;
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      // overflow: hidden;
      & > h4 {
        font-size: 2.5rem;
        color: white;
      }
      & > p {
        margin-top: 0.5rem;
        font-size: 1.5rem;
        color: white;
      }
      &-coupon {
        box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.2);
        display: absolute;
        position: absolute;
        // top: 24px;
        top: 0;
        left: -20px;
        //shift to left and making left as center roate 45 degree
        transform: translate(-40%, 40%) rotate(-45deg);
        width: 64rem;
        transform-origin: center;

        padding: 1rem;
        text-align: center;
        background-color: $primary;
        color: black !important;
        // content over flowing from the parent container should not be visible
      }
      &-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 2rem;
        width: 150px;
        border-radius: 3rem;
        padding: 1.5rem 2rem;
        border: 1px solid white;
        & > h6 {
          font-size: 2rem;
          color: white;
        }
        & > p {
          font-size: 1.8rem;
          color: white;
          font-weight: 600;
        }
      }
      &-feature {
        height: 300px;
        @include respond(tab-port) {
          height: fit-content;
        }
      }
    }
  }
}

// Correct  Sub components

.correct {
  & > p {
    color: white;
    font-size: 1.5rem;
  }
  & > * {
    &:first-child {
      color: #378d00;
      margin-right: 1.5rem;
      font-size: 2.4rem;
    }
  }
}

.cross {
  & > p {
    color: white;
    font-size: 1.5rem;
  }
  & > * {
    &:first-child {
      color: #ff3b3b;
      margin-right: 1.5rem;
      font-size: 2.4rem;
    }
  }
}

.noservices {
  & > p {
    color: white;
    font-size: 1.5rem;
  }
}

.bg-Bronze {
  background-color: $bronze;
}
.bg-Silver {
  background-color: $sliver;
}
.bg-Gold {
  background-color: $gold;
}
.bg-Platinum {
  background-color: $platinium;
}

// .noservices {
// }

.memberships-bottom-buttons {
  display: flex;
  align-items: flex-end;
}

.space {
  margin: 2rem;
}
