@import "../../scss/variable";
@import "../../scss/mixins";

.modal {
  position: absolute;
  background-color: white;
  border-radius: 1rem;
  z-index: 10000000000;
  overflow: hidden;
  width: 450px;
  margin: auto;
  padding: 3rem 2rem;
  top: 20%;

  @include respond(tab-port) {
    width: 70%;
  }
}

.modal-overlay {
  z-index: 10000000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background: rgba(0, 0, 0, 0.75);
}

.modal_container {
  // z-index: 100000000;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// For Modal Accept For Myservices delete service

.modal-accept {
  text-align: center;
  & > h3 {
    font-size: 2rem;
    margin: 2.5rem 0rem;
  }
  & > p {
    font-size: 1.5rem;
  }
  &-button {
    margin: 2rem 0rem;
    padding-top: 1rem;
    & > * {
      border-radius: 2rem;
      padding: 0.5rem;
      width: 90px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:first-child {
        margin-right: 1.5rem;
        border: 1.5px solid $primary;
        background-color: $primary;
        &:hover {
          background-color: white;
        }
      }
      &:last-child {
        border: 1.5px solid $primary;
        background-color: white;
        &:hover {
          background-color: $primary;
        }
      }
    }
  }
}

.modal-overlay-index {
  // background-color: red;
  z-index: 10000000;
}
