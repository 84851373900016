@import "../../scss/mixins";

.container {
  &-main {
    @include respond(tab-land) {
      width: 100%;
    }

    @include respond(big-desktop) {
      width: 1150px;
      margin: auto;
    }
  }
  &-container {
    width: 100%;
  }
  &-lg {
    width: 90%;
    margin: 0 auto;
  }
  &-md {
    width: 90%;
    margin: 0 auto;
    @include respond(tab-port) {
      width: 95%;
    }
    @include respond(phone) {
      width: 90%;
      margin: 0 auto;
    }
  }
  &-sm {
    width: 70%;
    margin: auto;
    @include respond(tab-port) {
      width: 90%;
    }
  }
  &-half {
    width: 50%;
    margin: auto;
    @include respond(tab-port) {
      width: 90%;
    }
  }
  &-extra-small {
    width: 40%;
    margin: auto;
    @include respond(tab-land) {
      width: 70%;
    }
    @include respond(tab-port) {
      width: 85%;
    }
  }
  &-slider {
    padding: 2rem;
    @include respond(tab-land) {
      margin: 0 2rem;
    }
  }
  &-responsive {
    //it should have like lg but on small screens,margin should be 0
    width: 90%;
    margin: 5rem;
    @include respond(tab-port) {
      width: 95%;
    }
    @include respond(phone) {
      width: 100%;
      margin: 0;
    }
  }
}
