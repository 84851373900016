@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.dashboard-cards {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(32rem, 1fr));

  // @include respond(big-desktop) {
  //   grid-template-columns: auto auto auto auto;
  // }

  // @include respond(laptop) {
  //   grid-template-columns: auto auto auto auto;
  // }

  // @include respond(tab-land) {
  //   flex-direction: column;
  // }

  // @include respond(tab-port) {
  //   grid-template-columns: auto auto;
  // }

  // @include respond(phone) {
  //   grid-template-columns: auto;
  // }
}

// Card Design
.dashboardcard {
  background: #fcfcfc;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 2.5rem 0rem;
  height: 40rem;
  cursor: pointer;
  display: grid;

  // @include respond(laptop) {
  //   width: 300px;
  // }

  @include respond(tab-land) {
    width: 100%;
    height: 40rem;
  }

  @media screen and (max-width: 1024px) {
    height: 220px;
  }

  &-container {
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: auto 1fr auto;

    & > h5 {
      font-size: 2rem;
      font-weight: 300;
    }

    &-item {
      margin: 6rem 0rem;

      & > h1 {
        font-size: 8rem;
        font-weight: 400;
        color: $primary;
      }

      & > h2 {
        font-size: 3.5rem;
        font-weight: 400;
        color: $primary;
        text-align: center;
      }

      & > img {
        height: 9.5rem;
      }
    }

    & > p {
      font-size: 1.4rem;
      font-weight: 300;
    }
  }
}

//

.applybutton {
  width: 100%;
  padding: 4rem 0;
  // padding: 4rem 5rem;
  // margin-right: 5rem;
  background-color: $primary;
  cursor: pointer;
  border: none;
  font-weight: 600;
  font-size: 3rem;
  text-align: center;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  border-radius: 1rem;

  & > * {
    color: white;
  }

  @include respond(tab-port) {
    // width: 100vw;
  }
}

.graph-container {
  width: 50vw;

  @include respond(tab-port) {
    width: 100vw;
  }
}
