@import "../../../scss/mixins";

.userlayout-navbar-nav {
  @include respond(tab-port) {
    display: none;
  }
}

.userlayout-navbar-mobile {
  display: none;
  @include respond(tab-port) {
    margin-top: 2rem;
    display: block;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
