@import "../../../scss/variable";
@import "../../../scss/mixins";

.customerdashboardlayout {
  &-container {
    display: flex;
    &-content {
      width: 100%;
      height: 100vh;
      overflow-y: hidden;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      @include respond(tab-port) {
        width: 100%;
        margin-left: 43px;
      }
      &-nav {
        height: 6.7rem;
      }
      &-outlet {
        // height: calc(100% - 6.7rem);
        min-height: calc(100% - 6.7rem);
        overflow-y: auto;
      }
    }
  }
}
