@import "../../../scss/variable";
@import "../../../scss/mixins";

.vendersignup {
  &-hero {
    display: flex;
    align-items: center;
    justify-content: center;
    &-heading {
      width: 300px;
      margin-right: 10rem;
      & > h1 {
        // margin-top: 4rem;
        padding-top: 5rem;
        font-size: 5rem;
        font-weight: 500;
        & > span {
          color: $primary;
        }
      }
      @include respond(tab-port) {
        width: 100%;
        margin-right: 0rem;
      }
    }
    &-image {
      width: 50%;
      & > img {
        width: 100%;
        max-height: 50rem;
        object-fit: cover;
        object-position: center center;
        border-radius: 0.8rem;
      }
      @include respond(tab-port) {
        display: none;
      }
    }
  }
  &-form {
    display: flex;
    align-items: center;
    justify-content: center;
    @include respond(tab-port) {
      flex-direction: column;
      width: 90%;
      margin: auto;
    }
    & > div {
      width: 50%;
      @include respond(tab-port) {
        width: 100%;
      }
    }
    & > * {
      &:first-child {
        margin-right: 8rem;
        @include respond(tab-port) {
          margin-right: 0px;
        }
      }
    }
  }
}

// .
