@import "../../scss/variable";
@import "../../scss/mixins";

.loginlayout {
  @include respond(tab-port) {
    margin-top: 5rem;
  }
  &-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-height: 100vh;
    place-items: center;

    @include respond(tab-port) {
      grid-template-columns: 1fr;
    }

    &-form {
      width: 100%;

      @include respond(tab-port) {
        max-width: 50rem;
        margin: 0 auto;
      }
    }
    &-image {
      height: 100%;
      & > img {
        width: 100%;
        height: 100%;
        object-fit: center;
        object-fit: cover;
      }
      @include respond(tab-port) {
        display: none;
      }
    }
  }
}
