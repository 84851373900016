@import "../../scss/variable";
.venderCard {
  &-container {
    &-image {
      & > img {
        height: 26rem;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        width: 100%;
        object-fit: cover;
      }
    }
    &-data {
      background-color: $light-gray;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
      padding: 1.5rem 2rem;
      margin-top: -8px;
      font-size: 1.3rem;
      color: black;
    }
  }
}
