@import "../../../../scss/variable";

.membership-top-section {
  background-color: #f2f1f1;
  padding: 0.2rem 3rem;
  border-radius: 3rem;
}

.membershipcardcheckout {
  height: 500px;
  width: 100%;
  max-width: 35rem;
  margin: 0 auto;
  position: relative;

  &-container {
    clip-path: polygon(100% 0, 100% 33%, 0 100%, 0 66%);
    background-color: black;
    height: 500px;
    // z-index: -100000;
    &-content {
      position: absolute;
      left: 10%;
      right: 10%;
      top: 10%;
      z-index: 10;
      height: 400px;
      top: 15%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      & > h4 {
        font-size: 2rem;
        color: white;
      }
      &-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 7rem;
        width: 150px;
        border-radius: 3rem;
        padding: 1.5rem 2rem;
        border: 1px solid white;
        & > h6 {
          font-size: 2rem;
          color: white;
        }
        & > p {
          font-size: 1.8rem;
          color: white;
          font-weight: 600;
        }
      }
    }
  }
}

// Correct  Sub components

.correct {
  & > p {
    color: white;
    font-size: 1.5rem;
  }
  & > * {
    &:first-child {
      color: #378d00;
      margin-right: 1.5rem;
      font-size: 2.4rem;
    }
  }
}

.cross {
  & > p {
    color: white;
    font-size: 1.5rem;
  }
  & > * {
    &:first-child {
      color: #ff3b3b;
      margin-right: 1.5rem;
      font-size: 2.4rem;
    }
  }
}

.noservices {
  & > p {
    color: white;
    font-size: 1.5rem;
  }
}

.bg-Bronze {
  background-color: $bronze;
}
.bg-Silver {
  background-color: $sliver;
}
.bg-Gold {
  background-color: $gold;
}
.bg-Platinum {
  background-color: $platinium;
}

// .noservices {
// }

.memberships-bottom-buttons {
  display: flex;
  align-items: flex-end;
}

.space {
  margin: 2rem;
}
