@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.verification-images-preview {
  margin-right: 4rem;
  & > div {
    box-sizing: border-box;
    padding: 1.6rem;

    & > img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 50rem;
      object-position: center center;
      object-fit: contain;
      box-sizing: border-box;
    }
  }

  @include respond(tab-port) {
    margin-top: 5rem;
    margin-right: 0rem;
  }
}

.verification-images-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-yes-or-no {
  margin: 2rem 0rem;
  & > label {
    display: block;
    font-size: 1.5rem;
    display: block;
    margin: 2rem 0.3rem;
    text-transform: capitalize;
  }
  & > select {
    width: 96%;
    border-radius: 1.3rem;
    padding: 1.3rem 0.5rem;
    &:focus {
      outline: none;
    }
    & > option {
      width: 96%;
    }
  }
}
