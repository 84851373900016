@import "../../../scss/variable";
@import "../../../scss/mixins";

.vendershop-profile {
  padding: 1.6rem;
  border-radius: 2.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &-detail {
    &-image {
      margin-right: 1rem;
      @include respond(tab-port) {
        margin-right: 1rem;
      }
      & > img {
        width: 53px;
        height: 53px;
        border-radius: 100%;
        object-fit: cover;
      }
    }
    &-content {
      & > div {
        & > h5 {
          font-size: 1.3rem;
          font-weight: 400;
          margin-right: 1rem;
          color: $gray;
        }
      }
      & > p {
        font-size: 1.3rem;
        margin: 5px 0px;
      }
    }
  }
  &-shop {
    & > img {
      width: 53px;
      height: 53px;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}

.shopcard {
  width: 100%;
  &-image {
    & > img {
      height: 30rem;
      border-radius: 2.5rem;
      width: 100%;
      object-fit: cover;

      @include respond(tab-port) {
        height: 27rem;
      }
    }
  }
  &-content {
    background-color: $primary;
    border-bottom-right-radius: 2.5rem;
    border-bottom-left-radius: 2.5rem;
    margin-left: 5px;
    margin-right: 5px;
    margin-top: -45px;
    padding-top: 6rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
    padding-right: 2rem;
    & > h4 {
      font-size: 1.6rem;
      font-weight: 400;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 1;
      height: 1.9rem;
    }
    & > p {
      font-size: 1.3rem;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
      -webkit-line-clamp: 4;
      height: 6.4rem;
    }

    @include respond(tab-port) {
      padding-top: 6.5rem;
    }
  }
}
