@import "../../../../scss/variable";
@import "../../../../scss/mixins";

.payment {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    gap:1rem;
    @include respond(tab-port) {
      flex-direction: column;
    }

    &-card {
      width: 70%;

      @include respond(tab-port) {
        width: 100%;
      }
    }

    &-button {
      @include respond(tab-port) {
        width: 100%;
        text-align: center;
        margin: 5rem 0rem;
      }
    }
  }
}

.payment-card {
  background: #f2f1f1;
  border: 1px solid $primary;
  padding: 4rem 3.5rem;
  border-radius: 3rem;

  &-heading {
    &>h3 {
      font-size: 1.8rem;
      font-weight: 600;
    }

    &>* {
      &:last-child {
        font-size: 2rem;
        cursor: pointer;
      }
    }
  }

  &-number {
    margin: 4rem 0rem;

    &>img {
      height: 4.6rem;
      width: 4.6rem;
      border-radius: 50%;
      margin-right: 2rem;
    }

    &>p {
      font-size: 2rem;
    }
  }

  &-cvc {
    &>h3 {
      font-size: 1.8rem;
      font-weight: 600;
    }

    &>p {
      margin-top: 1rem;
      font-size: 2rem;
    }
  }
  &-default{
    font-size: small;
    display: flex;
    gap: 1rem;
    align-items: center;
    float: right;
  }
}

.venderprofile {
  &-container {
    &-image {
      &>img {
        height: 143px;
        width: 143px;
        border-radius: 50%;
        border: 3px solid $primary;
      }
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &>div {
        width: 40%;
      }
    }
  }
}

.sectiondivider {
  background-color: $primary;
  border: none;
  height: 1.5px;
  width: 40%;
  margin: 3rem auto;
}

p{
  color: $gray;
  font-size: 1.4rem;
  margin: .3rem 0rem;
}