@import "../../scss/variable";

// New code

.linkbtn {
  & > * {
    background-color: $primary;
    color: black;
    padding: 1rem 3rem;
    cursor: pointer;
    border: none;
    transition: 0.3s ease-in-out;
    font-size: 1.5rem;
    font-weight: 700;
    border-radius: 1rem;
    &:hover {
      background-color: $primary-hover;
    }
  }
}
