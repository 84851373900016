@import "../../scss/variable";
@import "../../scss/mixins";

.workitem {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8rem 0rem;
  &-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45%;
    @include respond(tab-port) {
      width: 80%;
      flex-direction: column;
    }
    &-icon {
      margin-right: 2rem;
      width: 250px;
      text-align: center;
      & > * {
        font-size: 7rem;
      }
      @include respond(tab-port) {
        margin-bottom: 2.5rem;
      }
    }
    &-border {
      background: linear-gradient(180deg, #ffa800 0%, #ffc700 100%);
      border-radius: 28.5241px;

      width: 0.8rem;
      height: 20rem;
      @include respond(tab-port) {
        display: none;
      }
    }
    &-content {
      width: 230px;
      margin-left: 2rem;
      & > h3 {
        font-size: 1.2rem;
        font-weight: 600;
        margin: 1rem 0rem;
        text-transform: uppercase;
      }
      & > p {
        font-size: 1.2rem;
      }
    }
  }
}
