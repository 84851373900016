@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&family=Nunito:wght@200;300;400;500;600;700;800;900;1000&family=Oswald:wght@200;300;400;500;600;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto+Condensed:wght@400;700&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&family=Yesteryear&display=swap");

@import "./scss/variable";
@import "./scss/mixins";

* {
    -webkit-overflow-scrolling: touch;
}

body {
    font-family: "Montserrat";

    // font-family: "Warmth Winter";
}
// main container

// Common Style Classes
a {
    text-decoration: none;
    color: $heading-primary;
}

.d-flex {
    display: flex;
}
.align-item-center {
    display: flex;
    align-items: center;
}
.align-item-right {
    display: flex;
    justify-content: flex-end;
}
.space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.space-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.space-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.center {
    text-align: center;
}

.uppercase {
    text-transform: uppercase;
}

.text-justify {
    text-align: justify;
}

.m-1 {
    margin: 1rem 0rem;
}

.m-2 {
    margin: 2rem 0rem;
}

.m-3 {
    margin: 3rem 0rem;
}

.m-4 {
    margin: 4rem 0rem;
}

.m-5 {
    margin: 5rem 0rem;
}
.m-6 {
    margin: 6rem 0rem;
}

// Margin top
.mt-5 {
    margin-top: 5rem;
}
.mt-3 {
    margin-top: 3rem;
}

.ml-1 {
    margin-left: 1rem;
}

.pb-5{
    padding-bottom: 5rem;
}

// Paddings

.p-1 {
    padding: 0.5rem 0rem;
}
.p-2{
    padding: 1rem;
}
.p-3{
    padding: 1.5rem;
}
.p-4{
    padding: 2rem;
}

.question {
    & > * {
        color: #838282;
    }
}

tr,
th,
td {
    border: 1px solid $gray;
}

table {
    border-collapse: collapse;
}

tr:nth-child(even) {
    background-color: #dddddd91;
}

// For Seach Bar
.searchbar {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    & > input {
        margin-right: -25px;
        padding: 0.7rem 1.5rem;
        border-radius: 2rem;
        width: 240px;
        border: 1px solid black;
        &:focus {
            outline: none;
        }
    }
    &-icon {
        & > * {
            font-size: 1.6rem;
            cursor: pointer;
        }
    }
}

// For Forgot password LInk

.forgot-password-link {
    text-align: right;
    & > * {
        color: gray;
        font-size: 1.4rem;
        text-decoration: underline;
        text-transform: capitalize;
        margin-top: 1rem;
    }
}

.do-not-have-account {
    text-align: center;

    @include respond(tab-port) {
        flex-direction: column;
    }

    & > h4 {
        font-size: 1.2rem;
        color: $heading-primary;
    }
    & > * {
        &:last-child {
            @include respond(tab-port) {
                margin-top: 1rem;
            }
            font-size: 1.4rem;
            color: $primary;
            text-decoration: underline;
        }
    }
}

.already-have-account {
    display: flex;
    align-items: center;
    justify-content: center;
    & > h3 {
        font-weight: 400;
        font-size: 1.1rem;
        margin-right: 1rem;
    }
    & > * {
        &:last-child {
            font-weight: 700;
            font-size: 1.3rem;
            transition: 0.3s ease-in-out;
            &:hover {
                text-decoration: underline;
            }
        }
    }
}

.search-service-selector {
    width: 260px;
}

.inputfield-modal {
    padding-top: 4rem;
    padding-left: 1.5rem;
    padding-right: 1rem;
}

.loader {
    min-height: 100vh;
    min-height: 100svh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

html {
    scroll-behavior: smooth;
  }