.myaccount {
  &-container {
    &-image {
      & > img {
        height: 143px;
        width: 143px;
        border-radius: 50%;
      }
    }
  }
}
