@import "../../../scss/mixins";
// New code
.list-view {
  & > ol {
    margin-left: 5rem;
    @include respond(tab-port) {
      margin-left: 3rem;
    }
    & > li {
      font-size: 1.5rem;
      margin: 3rem;
      @include respond(tab-port) {
        margin: 2rem;
      }
      & > ul {
        list-style-type: disc;
        & > li {
          margin: 1rem;
          font-size: 1.5rem;
        }
      }
    }
  }
}
