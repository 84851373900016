@import "../../scss/variable";
@import "../../scss/mixins";

.hero {
  background-size: cover;
  background-repeat: no-repeat;
  // height: 90vh;
  width: 800px;
  background-position: top;
  margin-bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  // overflow-y: hidden;
  @include respond(big-desktop) {
    // height: 700px;
  }

  @include respond(tab-port) {
    height: 550px;
  }

  &-container {
    width: 80%;
    margin: auto;
    display: flex;
    align-items: center;

    @include respond(tab-port) {
      width: 90%;
    }

    &-content {
      margin: auto;
      width: 50%;

      @include respond(tab-port) {
        width: 100%;
      }

      & > h1 {
        font-size: 8rem;
        color: $primary;
        font-weight: 200;
        font-family: "Yesteryear";
        line-height: 10rem;

        @include respond(tab-land) {
          font-size: 6rem;
          line-height: 7rem;
        }
      }

      & > p {
        margin: 3rem 0rem;
        font-size: 1.7rem;
        color: rgba(219, 217, 217, 0.786);
        width: 80%;
      }
      & > input {
        border: none;
      }

      &-form {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr auto;
        gap: 2rem;
        max-width: 60rem;
        & > * {
          box-sizing: border-box;
        }

        @include respond(phone) {
          // align-items: center;
          // display: flex;
          // flex-wrap: wrap;
          width: 90%;
          margin: auto;
          grid-template-columns: 1fr auto;
          gap: 1rem;

          & > div:nth-child(1) {
            grid-column: 1/3;
            width: fit-content;
          }
        }

        &,
        & > div {
          & > input {
            width: 100%;
            border: 1px solid black;
            padding: 2.4rem 1.5rem;
            border-radius: 3rem;

            &:focus {
              outline: none;
            }

            &::-webkit-input-placeholder {
              /* Edge */
              font-size: 1.3rem;
              font-weight: 300;
            }

            &:-ms-input-placeholder {
              /* Internet Explorer 10-11 */
              font-size: 1.3rem;
              font-weight: 300;
            }

            &::placeholder {
              font-size: 1.3rem;
              font-weight: 300;
            }
          }
        }

        // & > * {
        //   &:not(:last-child) {
        //     margin-right: 2rem;
        //   }
        // }

        &-button {
          height: 37px;
          width: 37px;
          background-color: white;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          // border: 1px solid black;
          background-color: #ffc841;

          font-size: 2.3rem;
          color: black;

          @include respond(tab-port) {
            width: 5rem;
            height: 5rem;
          }

          & * {
            line-height: 0;
          }
        }
      }
    }

    &-slider {
      width: 30%;

      @include respond(tab-port) {
        display: none;
      }
    }
  }
}

.slide-Image {
  & > img {
    margin-top: 4rem;
    margin-bottom: 4rem;
    height: 600px;
    width: 100%;
    object-fit: cover;
    margin-right: 5rem;
  }
}
