@import "../../../scss/variable";

.aboutus {
  &-content {
    & > * {
      margin: 3rem 0rem;
    }
    & > img {
      width: 99%;
    }
  }
}

.stats {
  & > h1 {
    font-size: 5rem;
    font-weight: 800;
    color: $primary;
  }
  & > h4 {
    font-size: 1.8rem;
    color: $heading-primary;
  }
}
.aboutus-img {
  height: 52.4rem;
  border-radius: 48px;
  object-fit: cover;
}
