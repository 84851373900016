@import "../../../scss/variable";
@import "../../../scss/mixins";

.footer {
  background-color: $light-gray;
  padding: 2rem;
  &-logo {
    & > * {
      & > img {
        width: 15rem;
      }
    }
  }
  &-container {
    display: flex;
    padding: 2rem 0rem;
    @include respond(tab-port) {
      flex-direction: column;
    }
    &-left {
      width: 60%;
      @include respond(tab-port) {
        width: 100%;
      }
      & > * {
        &:not(:last-child) {
          margin-bottom: 3rem;
        }
      }
    }

    &-right {
      display: flex;
      flex-direction: column;
      @include respond(tab-port) {
        margin-top: 2rem;
      }
      & > * {
        margin-bottom: 1.8rem;
        font-size: 1.3rem;
        color: black;
        cursor: pointer;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}

// Social Icon

.socialIcon {
  & > img {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    cursor: pointer;
  }
}

.footer-end {
  display: flex;
  flex-direction: column;
  margin-left: 8rem;
  @include respond(tab-port) {
    margin-left: 0rem;
  }
  & > h5 {
    font-size: 1.3rem;
    margin-bottom: 1.8rem;
    color: $gray;
    font-weight: 500;
  }
  & > a {
    margin-bottom: 1.8rem;
    font-size: 1.3rem;
    color: black;
    cursor: pointer;
    text-decoration: none;
    text-transform: uppercase;
  }
}
