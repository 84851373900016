@import "../../../scss/variable";
@import "../../../scss/mixins";

.homepage-become-vender {
  background-image: url(../../../assests/vendorbg.svg);
  display: flex;
  align-items: center;
  padding-left: 8rem;
  height: 450px;
  background-position: center;
  background-size: cover;
  @include respond(big-desktop) {
    height: 450px;
  }
  @include respond(tab-port) {
    height: 500px;
  }

  &-container {
    width: 380px;
    @include respond(tab-port) {
      width: 80%;
    }
    & > h1 {
      color: $primary;
      font-weight: 800;
      font-size: 4rem;
    }
    & > p {
      color: white;
      font-weight: 300;
      font-size: 1.5rem;
      margin: 2.5rem 0rem;
      padding-bottom: 2rem;
    }
    &-button {
      & > * {
        color: black;
        background-color: $primary;
        padding: 1.4rem 6rem;
        border-radius: 4rem;
        cursor: pointer;
        font-size: 1.2rem;
        font-weight: 500;
        &:hover {
          background-color: $primary-hover;
        }
      }
    }
  }
}

.hero-container-content-input {
  border-radius: 50px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 20rem;
  height: 3rem;
  flex-shrink: 0;
  padding: 1rem;
  border: 1px solid black;
}
