@import "../../scss/variable";
.button {
  background-color: $primary;
  color: black;
  padding: 0.9rem 2.5rem;
  cursor: pointer;
  border: none;
  transition: 0.3s ease-in-out;
  font-size: 1.5rem;
  font-weight: 600;
  &:hover {
    background-color: $primary-hover;
  }
}
.rounded {
  border-radius: 1rem;
}
.pill{
  border-radius: 100px;
}

.btn-lighter {
  font-weight: 300;
}

.btn-lighter-full {
  font-weight: 300;
  width: 100%;
  padding: 2rem 0rem;
}
.btn-full{
  width: 100%;
}

button:disabled,
button:focus:disabled,
button:hover:disabled,
button:active:disabled {
  background: #ccc;
  border-color: #ccc;
  color: #666666;
  cursor: not-allowed;
}
